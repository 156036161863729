<script lang="ts" setup>
const modalStore = useModalStore();
const { updateFoodFamily } = useFoodFamilyStore();
const { setValidation, required } = useValidation();

const props = defineProps({
  foodFamily: {
    type: Object,
    default: () => {},
  },
});

const foodFamilyData = reactive({
  label: props.foodFamily.label,
  description: props.foodFamily.description,
});

const validation = setValidation(foodFamilyData, {
  label: { required },
  description: { required },
});

async function handleUpdateFoodFamily() {
  await updateFoodFamily(props.foodFamily.id, foodFamilyData);
  modalStore.closeModal();
}
</script>
<template>
  <BaseModal title="Editer une famille d'aliment">
    <form>
      <BaseInput
        v-model="validation.label.$model"
        label="Label"
        type="text"
        :validation="validation.label"
        required
      />

      <BaseInput
        v-model="validation.description.$model"
        label="Description"
        type="text"
        :validation="validation.description"
      />
    </form>

    <template #footer>
      <BaseButton
        text="Annuler"
        type="secondary"
        @click="modalStore.closeModal()"
      />
      <BaseButton text="Confirmer" @click="handleUpdateFoodFamily" />
    </template>
  </BaseModal>
</template>
